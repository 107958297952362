<template>
  <div class="page">

    <h1>AVB</h1>

    <h2>Allgemeine Vermietbedingungen</h2>

    <h3>I. Vorwort</h3>

    <p>Vielen Dank, dass Sie sich für die InTime Autovermietung entschieden haben.</p>
    <p>Die Leistungen aus dem  Vertragsverhältnis bestimmen sich nach den vorliegenden Allgemeinen Vermietbedingungen (im folgenden Bedingungen genannt).</p>
    <p>Gemäß den vorliegenden Bedingungen werden von der InTime Autovermietung bei Abschluss eines Mietvertrages die Vermietung eines Fahrzeuges für den im Mietvertrag genannten Zeitraum, einschließlich des konkret vermieteten Fahrzeuges, welches ebenfalls im Mietvertrag aufgeführt ist, als Leistung erbracht.</p>

    <h3>II. Maßgebliche Dokumente</h3>

    <p>Die für das Vertragsverhältnis zwischen dem Mieter und Vermieter notwendigen Dokumente sind:</p>
    <ol>
      <li>Der Mietvertrag</li>
      <li>die Buchungsbestätigung per Mail, sofern das Fahrzeug online angemietet wurde</li>
      <li>Das Übergabeprotokoll vor Übergabe des Fahrzeuges an den Mieter und bei Rückgabe des Fahrzeuges an den Vermieter</li>
      <li>die hier geltenden aktuellen Bedingungen</li>
    </ol>

    <h3>III. Anwendungsbereich</h3>

    <p>Die Bedingungen gelten für den Mieter, der für die vertraglich vereinbarte Fahrzeugmiete und der sonstigen Kosten zahlungspflichtig ist. Mieter ist derjenige, der im Mietvertrag als Mieter eingetragen ist und für den Mietzins und der sonstigen geschuldeten Kosten haftet.</p>
    <p>Ferner gelten die Bedingungen für den Mieter als Fahrer und für jeden weiteren Fahrer, der ausdrücklich als solcher im Mietvertrag mit aufgenommen wurde und hierdurch berechtigt ist, das Fahrzeug zu führen (folgend: berechtigter Fahrer).</p>

    <h3>IV. Anmieteberechtigte Personen und berechtigte Fahrzeugführer</h3>

    <p><u>1. Personen, die zur Anmietung berechtigt sind – Wer kann als „Mieter“ im Vertrag geführt werden?</u></p>

    <p>Die Anmietung von Fahrzeugen der InTime Autovermietung darf durch jede natürliche und juristische Person erfolgen, die rechts – und geschäftsfähig ist und bei der Anmietung des Fahrzeuges folgende gültigen Dokumente vorlegt:</p>

    <ul>
      <li>Einen in Deutschland gültigen Führerschein in lateinischer Schrift (europäischer oder internationaler Führerschein oder beglaubigte Übersetzung)</li>
      <li>Einen gültigen Personalausweis oder einen gültigen Reisepass</li>
      <li>Nachweis der aktuellen Anschrift durch hierfür geeignete Dokumente, zB Personalausweis, Reisepass, Rechnung eines Versorgungsunternehmens.</li>
    </ul>

    <p><u>2. berechtigte Fahrzeugführer – von wem darf das Fahrzeug gefahren werden?</u></p>

    <p>Zum Führen des angemieteten Fahrzeuges ist jede natürliche Person berechtigt, die</p>

    <ul>
      <li>Ausdrücklich mit vollständigen Namen und der gültigen Wohnanschrift im Mietvertrag als Mieter oder als zusätzlicher Fahrer eingetragen ist</li>
      <li>Ein gültiges Ausweisdokument und einen gültigen Führerschein gemäß IV.a) vorgelegt hat.</li>
      <li>Mindestens 18 Jahre alt ist. Bei Fahrern unter 23 Jahren wird eine zusätzliche Gebühr erhoben.</li>
    </ul>

    <p>Personen, die nicht im Mietvertrag als zusätzliche Fahrer eingetragen sind, sind nicht berechtigt, das Fahrzeug zu führen.</p>
    <p>Gestattet der Mieter einer natürlichen Person, die nicht im Mietvertrag als zusätzlicher Fahrer eingetragen ist, das Führen des angemieteten Fahrzeuges, so stellt dies eine Verletzung der vertraglich vereinbarten Bedingungen dar. Kommt es infolge des Führens des Fahrzeuges durch einen nicht berechtigten Fahrer zu einem Schaden, so haftet der Mieter für diesen Schaden.</p>
    <p>Der nicht berechtigte Fahrer genießt keinen Versicherungsschutz oder Schutz weiterer vertraglich vereinbarter Zusatzleistungen. Der Schutz durch die gesetzliche Haftpflichtversicherung bleibt hiervon allerdings unberührt (unabdingbarer Versicherungsschutz).</p>

    <h3>V. Vertragsgebiet und Fahrten im Ausland</h3>

    <p>Das Fahrzeug darf nur in im Vertragsgebiet geführt werden. Vertragsgebiet ist ganz Europa mit Ausnahme folgender Länder:</p>

    <ul>
      <li>Aserbaidschan, Iran, Russland</li>
    </ul>

    <p>Das Führen eines Fahrzeuges in Serbien und Zypern ist auf diejenigen Gebiete beschränkt, die unter der Kontrolle der jeweiligen Regierung stehen. Im Falle einer beabsichtigten Fahrt nach Serbien oder Zypern findet der Mieter weitere Informationen hierzu auf https://www.cobx.org/article/41/gc-territorial-validity-limitations.</p>
    <p>Bei Fahrten außerhalb Deutschlands ist der Mieter und der berechtigte Fahrer zur Beachtung der entsprechenden ausländischen Gesetze, Verkehrsvorschriften und etwaiger Mautpflichten selbst verantwortlich. Der Mieter und der berechtigte Fahrer sind auch dafür verantwortlich, die nach ausländischem Recht notwendige Ausrüstung im Fahrzeug mitzuführen und deren Vorhandensein selbst sicherzustellen (z.B. Anzahl der Warnwesten im Fahrzeug)</p>
    <p>Der Mieter und der berechtigte Fahrer haften für alle Ansprüche, die sich während der Mietzeitraumes aus der Halterhaftung nach ausländischem Recht ergeben.</p>

    <h3>VI. Haftung des Vermieters für mitgeführte Gegenstände, transportierte Gegenstände und das damit einhergehende Risiko</h3>

    <p>Der Vermieter haftet nicht für Schäden an Gegenständen, die der Mieter, der berechtigte Fahrer und alle weiteren Mitfahrer mit sich führen.</p>
    <p>Ferner haftet der Vermieter nicht für den Schaden an solchen Gegenständen, die mittels des Mietfahrzeuges durch den Mieter oder den berechtigten Fahrer transportiert werden. Für das mit diesen Gegenständen einhergehende Risiko haftet der Vermieter ebenfalls nicht.</p>
    <p>Der Vermieter haftet auch nicht für den Verlust einer Geschäftsmöglichkeit oder einer Betriebsunterbrechung im Zusammenhang mit der Vermietung des Fahrzeuges.</p>

    <h3>VII. Pflichten des Mieters und des Fahrers in Bezug auf das Mietfahrzeug</h3>

    <p>1. Der Mieter und der berechtigte Fahrer sind verpflichtet, das Fahrzeug mit der verkehrsüblichen Sorgfalt zu behandeln. Sie sind verpflichtet, das Fahrzeug abzuschließen, wenn dieses geparkt und unbeaufsichtigt ist.</p>
    <p>Der Mieter und der berechtigte Fahrer dürfen das Fahrzeug nicht führen, wenn die Fahrtüchtigkeit beeinträchtigt ist. Dies gilt insbesondere dann, wenn der Mieter oder der berechtigte Fahrer unter Alkohol  - oder Drogeneinfluss stehen. Im Schadenfall behält sich der Vermieter die Geltendmachung der Schadensersatzansprüche vor.</p>
    <p>Das Rauchen in den Fahrzeugen ist untersagt. Im Falle dessen, dass der Mieter, der berechtigte Fahrer oder andere beförderte Dritte diesem Verbot zuwiderhandeln, ist der Vermieter berechtigt, eine Schadensersatzpauschale in Höhe von bis zu € 50,00 gegen den Mieter geltend zu machen. Dem Mieter bleibt es gestattet nachzuweisen, das ein Schaden überhaupt nicht oder nicht in der Höhe der beanspruchten Pauschale entstanden ist.</p>
    <p>Die Beförderung von Haustieren ist nur nach Vereinbarung mit dem Vermieter gestattet. Der Vermieter behält sich im Fall einer vertragswidrigen Beförderung vor, die Kosten für die Reinigung des Fahrzeuges, die aufgrund der Verschmutzung durch das Tier verursacht wurden (Sonderreinigungskosten), dem Mieter in Rechnung zu stellen, wobei dem Mieter gestattet wird den Nachweis darüber zu erbringen, dass die in Rechnung gestellten Reinigungskosten nicht oder nicht in dieser Höhe angefallen sind. Diese Sonderreinigungskosten werden nach Aufwand, mindestens aber mit einer Sonderreinigungspauschale in Höhe von € 50,00 berechnet.</p>
    <p>Im Falle starker Verschmutzungen des Fahrzeuges (zB Schlamm) oder des Fahrzeuginnenraumes (zB Kaugummi, verschüttete Getränke, etc) durch den Mieter oder durch mitbeförderter Personen behält sich der Vermieter ebenfalls vor, die Kosten für die Reinigung dem Mieter in Rechnung zu stellen. Diese Sonderreinigungskosten werden nach Aufwand, mindestens aber mit einer Sonderreinigungspauschale in Höhe von € 50,00 berechnet. Dem Mieter bleibt der Nachweis gestattet, dass die geltend gemachten Kosten überhaupt nicht oder nicht in der Höhe entstanden ist.</p>
    <p>2. Der Mieter hat sicherzustellen, dass Gepäck und weitere Gegenstände, die im Fahrzeug transportiert werden, derart gesichert sind, dass durch sie kein Schaden am Fahrzeug verursacht wird und mitfahrende Personen durch sie nicht gefährdet oder verletzt werden. Der Mieter hat die geltenden Vorschriften zur Ladungssicherung zu beachten und zu befolgen.</p>
    <p>3. Der Mieter ist dazu verpflichtet, das Fahrzeug während der Mietzeit in einem vertragsgemäßen Zustand zu erhalten und alle Maßnahmen zu ergreifen, die hierfür erforderlich und geeignet sind.</p>
    <p>Das Fahrzeug wird dem Mieter mit allen erforderlichen Betriebsstoffen übergeben. Während der Mietzeit ist der Mieter und der berechtigte Fahrer dazu verpflichtet, das Fahrzeug mit dem erforderlichen und für das Fahrzeug zugelassenen Betriebsstoffen bei Erforderlichkeit und zu befüllen  (zB Kühlwasser, Öl, Wischwasser, Kraftstoff, AdBlue). Für den Fall, dass das Fahrzeug mit dem falschen Kraftstoff betankt wird, haftet der Mieter für die hierdurch entstehenden Abschlepp – und/oder Reparaturkosten auf Basis der in Ziff XIII vereinbarten Bedingungen (Haftung des Mieters bei Schäden am Fahrzeug). Dem Mieter steht es jedoch offen nachzuweisen, dass einem Dritten die Falschbetankung zuzurechnen ist.</p>
    <p>4. Der Mieter und der berechtigte Fahrer sind dazu verpflichtet, das Fahrzeug entsprechend der geltenden Gesetze und Vorschriften zu führen. Sie haben sicherzustellen, dass sie mit den vor Ort geltenden Verkehrsvorschriften (In – und Ausland) vertraut sind und diese zu befolgen. Der Mieter und der berechtigte Fahrer haften für alle im Zusammenhang mit der Führen des Fahrzeuges anfallenden Gebühren, Mautkosten, Abgaben, Bußgeldern und Strafen, für die der Vermieter in Anspruch genommen wird, sofern und soweit sie diese zu vertreten haben.</p>
    <p>5. Der Mieter und der berechtigte Fahrer sind dazu verpflichtet, das Fahrzeug, den Fahrzeugschlüssel sowie das bei Mietbeginn übergebene Zubehör zum vereinbarten Zeitpunkt, am vereinbarten Ort und zu der vereinbarten Uhrzeit dem Vermieter wieder zu übergeben. Fahrzeug, Schlüssel und Zubehör sind in dem Zustand zurückzugeben, in welchem Sie bei Mietbeginn Seitens des Vermieters zur Verfügung gestellt wurden.</p>
    <p>6. Die Verwendung oder das Zurverfügungstellen des Fahrzeuges durch den Mieter oder den berechtigten Fahrer ist für folgende Zwecke untersagt:</p>
    <p>a) Das Fahrzeug, die Fahrzeugteile, die Fahrzeugschlüssel, das Zubehör, die Ausrüstung oder andere Gegenstände, die sich im Eigentum des Vermieters befinden, dürfen nicht weitervermietet, belastet, verpfändet, verkauft oder in anderer Form belastet werden</p>
    <p>b) Zur Personenbeförderung gegen Miete oder Bezahlung (zB Car Sharing), es sei denn, eine solche Verwendung ist mit dem Vermieter ausdrücklich vertraglich vereinbart.</p>
    <p>c) Es dürfen nicht mehr Personen befördert werden, als in den Fahrzeugdokumenten eingetragen und erlaubt sind.</p>
    <p>d) Beförderung von entflammbaren Stoffen und/oder Gegenständen, toxische und/oder radioaktive Produkte oder solche Produkte und/oder Gegenstände, die die geltenden gesetzlichen Bestimmungen verletzen. Von dieser Vorschrift ausgenommen sind Produkte des täglichen Lebens (zB Haarspray, Feuerzeuge, Deo) sofern sie einer normalen und üblichen Nutzung eines Fahrzeuges nicht entgegenstehen.</p>
    <p>e) Das Mietfahrzeug darf nicht für Rennen genutzt werden. Dies gilt auch dann, wenn eine Rennstrecke für die Allgemeinheit zugänglich und erlaubt ist.</p>
    <p>f) Beförderung lebender Tiere, sofern es sich nicht um Haustiere in den hierfür vorgesehenen Transportboxen handelt und der Transport gemäß VII. Ziff. 1 gestattet ist.</p>
    <p>g) Nutzung des Fahrzeuges für Fahrschulzwecke oder zum Zweck Begleitenden Fahrens.</p>
    <p>h) Sofern das angemietete Fahrzeug keine Anhängerkupplung aufweist, ist es untersagt, das Mietfahrzeug zum Ziehen oder Schieben eines anderen Fahrzeuges zu verwenden.</p>
    <p>i) Zur Begehung einer vorsätzlichen Straftat oder Ordnungswidrigkeit, auch wenn diese nur nach dem Recht des Tatortes mit Strafe bedroht sind.</p>
    <p>j) Das angemietete Fahrzeug darf nicht an Bord eines Flugzeuges transportiert werden.</p>
    <p>k) Das Mietfahrzeug darf nicht innerhalb der nicht für den Verkehrs zugelassenen Bereichen von Häfen, Flughäfen und/oder Flugplätzen geführt werden. Gleiches gilt für Bereiche, den genannten Bereichen entsprechen und nicht oder die dem öffentlichen Verkehr keinen Zugang gestatten.</p>
    <p>l) Zur sonstigen Nutzung, die nicht dem vertragsgemäßen Gebrauch entsprechen.</p>
    <p>Der Mieter haftet für alle Schäden und Folgen, die sich aus der Verletzung vorstehender Verpflichtungen ergeben. Der Vermieter behält sich das Recht vor, im Falle einer Verletzung vorstehender Verpflichtungen die sofortige Rückgabe des Fahrzeuges zu fordern und gegebenenfalls Schadensersatzansprüche gegen den Mieter und/oder den berechtigten Fahrer geltend zu machen.</p>

    <h3>VIII. Was ist im Mietpreis enthalten?</h3>
    <p><u>1. Grundsätzlich enthalten</u></p>
    <p>Im Mietpreis enthalten sind folgende Mobilitätsleistungen:</p>
    <p>1. Das vertraglich angemietete Fahrzeug für die vertraglich angemietete Zeit</p>
    <p>2. Die gesetzliche Kraftfahrzeug-Haftpflichtversicherung</p>
    <p>3. Die vertraglich vereinbarten und vom Mieter gebuchten Kilometer. Für jeden darüber hinaus gefahrenen Kilometer berechnet der Vermieter eine Zusatzzahlung von € 0,35 inkl Mwst/Kilometer.</p>
    <p>4. die Umsatzsteuer</p>
    <p>5. Weitere Leistungen des Vermieters, die nach Vereinbarung der Parteien zusätzlich vom Mieter gebucht wurden.</p>
    <p><u>2. Optionale Zusatzleistungen gegen Entgelt</u></p>
    <p>Haftungsreduktion nach dem Leitbild einer Vollkaskoversicherung: Die Haftungsreduktion nach dem Leitbild einer Vollkaskoversicherung kann vom Mieter als Zusatzleistung und gegen Entgelt vereinbart werde. Die Haftungsbegrenzung gilt für Schadensfälle, die in der Vollkaskoversicherung nach den Allgemeinen Bedingungen für die Kfz-Versicherung (AKB) versichert sind.</p>

    <h3>IX. Weitere mögliche Kosten</h3>

    <p>1. Neben dem Mietpreis fordert der Vermieter den Mieter zur Leistung einer Sicherheit (Kaution) auf. Die Höhe der Kaution wird im Mietvertrag zwischen den Parteien vereinbart. Die Sicherheitsleistung dient der Absicherung für mögliche zusätzliche Kosten, die während der Nutzung des Mietfahrzeuges entstehen können. Diese Sicherheit wird in Form von Geld gewährt. Sie dient zur Absicherung folgender möglicher zusätzlicher Kosten:</p>

    <ul>
      <li>Bearbeitungspauschalen: Sofern aufgrund der Nutzung des Mietfahrzeuges Bußgelder oder Mautgebühren Seitens des Vermieters bearbeitet werden müssen. Dem Mieter bleibt der Nachweis gestattet, dass die geltend gemachte Pauschale nicht oder nicht in der geltend gemachten Höhe angefallen ist. Die Pauschale ist unabhängig von anfallenden Bußgeldern oder der Maut. Für ein etwaiges Bußgeld oder die Maut haftet der Mieter oder der berechtigte Fahrer persönlich. Die Bearbeitungspauschale kann mit bis zu € 50,00 dem Mieter in Rechnung gestellt werden.</li>
      <li>Reinigungskosten: Sofern das Fahrzeug über eine vertragsgemäße Nutzung hinaus verschmutzt ist und in diesem Zustand an den Vermieter wieder übergeben wird. Diese Sonderreinigungskosten werden nach Aufwand, mindestens aber mit einer Sonderreinigungspauschale berechnet. Dem Mieter bleibt der Nachweis gestattet, dass die geltend gemachten Kosten überhaupt nicht oder nicht in der Höhe entstanden ist.</li>
      <li>Kosten für den Verlust von Fahrzeugschlüsseln oder den verlorenen Fahrzeugschein, soweit dies vom Mieter oder dem berechtigten Fahrer zu vertreten ist. Der Vermieter verpflichtet sich, dem Mieter eine Rechnung über die Ersatzbeschaffung vorzulegen.</li>
      <li>Überzogene Kilometer: Sofern mit dem Fahrzeug mehr Kilometer gefahren werden, als vertraglich zwischen den Parteien vereinbart wurde.</li>
      <li>Tankfüllung nach Rückgabe des Fahrzeuges: Sofern das Fahrzeug nicht mit einem vollen Tank an den Vermieter übergeben wird, behält sich der Vermieter das Recht vor, die für die Betankung des Fahrzeuges notwendigen Kosten von der Kaution einzubehalten. Dem Mieter wird in diesem Fall ein Nachweis über die Betankung des Fahrzeuges in Form einer Quittung übergeben.</li>
      <li>Verspätete Rückgabe: Sofern dem Vermieter durch die verspätete Rückgabe nach der Maßgabe der Ziff. XI Nr.3 einen Anspruch auf Nutzungsentgelt zusteht, behält sich der Vermieter das Recht vor, dieses von der Kaution einzubehalten.</li>
    </ul>

    <p>Die Kaution wird, sofern diese vom Vermieter nicht in Anspruch genommen wird, an den Mieter nach Rückgabe des Fahrzeuges in Bar zurückgezahlt. Sofern der Mieter die Kaution per EC/Kredit überwiesen hat, wird eine Stornierung des Bezahlvorgangs vorgenommen.</p>
    <p>2. Für berechtigte Fahrer unter 23 Jahren erlaubt sich der Vermieter eine zusätzliche Gebühr in Höhe von € 10,00 inkl. Mwst. pro Tag.</p>

    <h3>X. Regeln bei der Fahrzeugabholung</h3>

    <p>Das angemietete Fahrzeug wird dem Mieter vom Vermieter an dem vereinbarten Abholort übergeben. Vermieter und Mieter fertigen gemeinsam ein Übergabeprotokoll, in welchem die bestehenden Schäden am Fahrzeug protokolliert werden. Dieses Protokoll ist von beiden Parteien zu unterzeichnen.</p>
    <p>Stellt der Mieter während der Übergabe des Fahrzeuges einen Schaden fest, der nicht im Übergabeprotokoll vermerkt ist, ist der Mieter dazu verpflichtet, den durch ihn festgestellten Schaden im Übergabeprotokoll während der Übergabe vermerken zu lassen. Gleiches gilt bei der Übergabe des vertraglich vereinbarten und übergebenen Zubehöres und/oder Werkzeuges.</p>
    <p>Der Mieter führt darüber hinaus einen Funktionstest folgender Fahrzeugbestandteile durch:</p>
    <ul>
      <li>Abstandsmesssensoren</li>
      <li>Elektronik</li>
      <li>Blinker</li>
      <li>Scheibenwischer</li>
    </ul>

    <p>Die Durchführung des Funktionstest wird von beiden Parteien auf dem Übergabeprotokoll durch Unterschrift bei der Übergabe bestätigt.</p>

    <h3>XI. Regeln bei der Fahrzeugübergabe</h3>

    <p>Der Mieter verpflichtet sich, das Fahrzeug am mietvertraglich vereinbarten Tag und zu der vereinbarten Uhrzeit am vereinbarten Übergabeort an den Vermieter zurückzugeben.</p>

    <p><u>1. Rückgabe des Fahrzeuges zu den üblichen Öffnungszeiten des Vermieters:</u></p>
    <p>Wird das Fahrzeug zu den üblichen Öffnungszeiten des Vermieters zurückgegeben, so fertigen die Parteien gemeinschaftlich ein Rückgabeprotokoll.  Das Fahrzeug wird hierfür vom Mieter und vom Vermieter gemeinsam besichtigt. Das Rückgabeprotokoll wird von beiden Parteien unterzeichnet.</p>
    <p>Dem Mieter ist auf Verlangen ein Rücknahmedokument herauszugeben.</p>
    <p>Die Mietzeit endet, wenn dem Vermieter das Fahrzeug zurückgegeben und die Fahrzeugschlüssel und das sonstige Zubehör ausgehändigt werden.</p>
    <p>Wird das Fahrzeug zu einem früheren Zeitpunkt als den im Vertrag vereinbarten Beendigungszeitpunkt abgegeben, so hat der Mieter keinen Anspruch Rückerstattung der Kosten für die restliche Mietzeit. Dem Mieter bleibt der Nachweis gestattet, dass dem Vermieter durch die verfrühte Rückgabe kein Schaden entstanden ist oder der Schaden geringer ist als der vom Vermieter einbehaltene Mietzins.</p>

    <p><u>2. Rückgabe des Fahrzeuges außerhalb der Öffnungszeiten des Vermieters</u></p>
    <p>Wird das angemietete Fahrzeug außerhalb der Öffnungszeiten an den vereinbarten Ort abgestellt, so ist der Mieter verpflichtet, den Fahrzeugschlüssel in eine dafür vorgesehene Vorrichtung zu hinterlegen.</p>
    <p>Die entsprechende Vorrichtung wird dem Mieter durch den Vermieter auf Anfrage des Mieters mitgeteilt.</p>
    <p>Bei der Rückgabe des Fahrzeuges außerhalb der üblichen Öffnungszeiten wird ein Rückgabeprotokoll durch den Vermieter in Abwesenheit des Mieters durchgeführt. Die Überprüfung des Fahrzeuges erfolgt am nächsten Werktag zu den üblichen Öffnungszeiten.</p>
    <p>Der Mieter ist deshalb verpflichtet, jeden in der Mietzeit auftretenden Schaden, dem Vermieter mitzuteilen. Die Schadenmeldung erfolgt schriftlich oder per Email.</p>
    <p>Der Mieter ist verpflichtet, das Fahrzeug verkehrssicher am vereinbarten Übergabeort abzustellen. Der Mieter hat sicherzustellen, dass das Fahrzeug keine Gefahr für Dritte darstellt und den Verkehr nicht behindert.</p>
    <p>Der Vermieter ist verpflichtet, einen im Zuge der Überprüfung des Fahrzeuges festgestellten Schaden dem Mieter unverzüglich mitzuteilen. Dem Mieter wird deshalb angeraten, vor der Hinterlegung des Fahrzeugschlüssels Lichtbilder vom Zustand des Fahrzeuges zu fertigen, um diesen bei Übergabe und zum Übergabezeitpunkt zu dokumentieren.</p>

    <p><u>3. Rückgabe des Fahrzeuges nach der vereinbarten Mietdauer (verspätete Rückgabe)</u></p>
    <p>Wird das angemietete Fahrzeug nicht zum vereinbarten Zeitpunkt abgegeben und meldet der Mieter nicht unverzüglich bei Kenntnis über die Verspätung dem Vermieter den Grund der Verspätung, so geht der Vermieter von einer widerrechtlichen Nutzung aus und behält sich die Anzeige der widerrechtlichen Nutzung bei den Behörden vor.</p>
    <p>Im Fall der verspäteten Rückgabe ist der Vermieter berechtigt, für jeden Tag der über den vereinbarten Rückgabezeitpunkt erfolgten Nutzung des Fahrzeuges hinaus ein Nutzungsentgelt auf der Grundlage des vereinbarten Tarifs zu berechnen. Dem Mieter bleibt der Nachweis gestattet, dass ihn kein Verschulden an der Verspätung der Rückgabe trifft.</p>
    <p>Der Vermieter ist berechtigt, gerichtliche Schritte zur unverzüglichen Rückgabe des Fahrzeuges bei unberechtigter Nutzung einzuleiten. In diesem Fall besteht (mit Ausnahme der gesetzlichen Haftplichtversicherung) kein vereinbarter Versicherungsschutz. Auch sonstige vertragliche Leistungen werden nicht mehr gewährt.</p>
    <p>Der Vermieter behält sich das Recht vor, alle Schäden, die auf Grund der verschuldet verspäteten Rückgabe entstanden sind, beim Mieter geltend zu machen. Hierzu zählen insbesondere</p>

    <ul>
      <li>entgangener Gewinn aus der Unmöglichkeit einer Anschlussvermietung, wobei dem Mieter der Nachweis gestattet bleibt, dass ein Schaden nicht oder nicht in der geltend gemachten Höhe eingetreten ist.</li>
      <li>Bußgelder</li>
      <li>Staatliche Forderungen gegen den Vermieter aufgrund von Maßnahmen zur Identifikation der Person der unberechtigten Nutzung</li>
      <li>Staatliche Forderung gegen den Vermieter aufgrund von Ermittlungen zur Klärung von Tatsachen einer strafbaren Handlung oder Ordnungswidrigkeit im Zusammenhang mit dem angemieteten Fahrzeug</li>
      <li>Mautgebühren</li>
    </ul>

    <p><u>4. Rückgabe des Fahrzeuges und Mietzins bei Verlängerung des Mietvertrages</u></p>

    <p>Vereinbarten die Parteien während der Anmietedauer eine Verlängerung des Mietvertrages, so ist der Mieter verpflichtet, das Fahrzeug zum neu vereinbarten Zeitpunkt und zum (neu) vereinbarten Rückgabeort an den Vermieter zurückzugeben. Im Fall einer Mietvertragsverlängerung geltend die gleichen Tarife wie im vorher vereinbarten Mietvertrag, es sei denn, es werden durch die Parteien ausdrücklich neue Mietvereinbarungen getroffen.</p>

    <p><u>5. Tankregelung: Voll zu Voll</u></p>

    <p>Dem Mieter wird das angemietete Fahrzeug mit einem vollen Kraftstofftank übergeben. Bei Rückgabe des Fahrzeuges ist der Mieter verpflichtet auf eigene Kosten den Fahrzeugtank voll zu betanken und im vollen Zustand wieder an den Vermieter zurückzugeben.</p>

    <h3>XII. Verhalten des Mieters bei einem Unfall, einer Panne, Diebstahl oder sonstigen Schäden am Fahrzeug</h3>

    <p>Im Falle eines Unfalles, eines Diebstahls, eines Brand – oder Wildschadens ist der Mieter oder der berechtigte Fahrer dazu verpflichtet, unverzüglich die Polizei zu informieren und den Vermieter unverzüglich über den Schaden in Kenntnis zu setzen.</p>

    <p><u>1. Unfall</u></p>
    <p>Die Verpflichtung zur Verständigung der Polizei und des Vermieters gilt unabhängig davon, ob der Schaden selbst oder durch einen Dritten verursacht wurde. Der Mieter und/oder der berechtigte Fahrer darf etwaige Ansprüche eines Dritten nicht anerkennen.</p>
    <p>Ferner gilt diese Verpflichtung auch unabhängig von der Höhe des Schadens. Auch kleinere Schäden sind unverzüglich der Polizei und dem Vermieter anzuzeigen.</p>
    <p>Der Mieter und/oder der berechtigte Fahrer ist verpflichtet, ein Schadenformular auszufüllen, welches Seitens des Vermieters zur Verfügung gestellt wird und welches sich im Fahrzeug befindet.</p>
    <p>Zwingend vom Mieter anzugeben sind</p>
    <ul>
      <li>Datum und Uhrzeit des Unfalls/Schadens</li>
      <li>Unfallort und Straße</li>
      <li>Kennzeichen der weiteren am Unfall beteiligten Fahrzeuge sowie Name und Anschrift der am Unfall beteiligten Personen. Hierzu gehören auch Name und Anschrift von möglichen Zeugen</li>
      <li>Die aufnehmende Polizeidienststelle</li>
      <li>Der Unfallhergang anhand einer Unfallskizze sowie einer kurzen schriftlichen Schilderung des Unfallhergangs</li>
    </ul>
    <p><u>2. Diebstahl</u></p>
    <p>Im Falle des Diebstahls ist der Mieter verpflichtet, unverzüglich die Polizei zu verständigen und den Vermieter unverzüglich zu informieren. Der Mieter hat dem Vermieter die Fahrzeugschlüssel und die Fahrzeugpapiere (sofern diese nicht entwendet wurden) unverzüglich zu übergeben. Ferner muss eine Kopie der Strafanzeige gefertigt und dem Vermieter zur Verfügung gestellt werden.</p>
    <p><u>3. Panne</u></p>
    <p>Im Falle einer Panne ist der Mieter und/oder der berechtigte Fahrer verpflichtet, den Vermieter unverzüglich über den Vorfall in Kenntnis zu setzen.</p>
    <p><u>Sonstige Schäden</u></p>
    <p>Bei Schäden, die keinen Unfall, eine Panne oder einen Diebstahl darstellen, ist der Mieter verpflichtet, den Vermieter unverzüglich über den Schaden und den Schadenhergang zu informieren. Gleichzeitig ist er dazu verpflichtet, das im Fahrzeug befindliche Schadenformular auszufüllen.</p>
    <p>Die Haftung des Mieters bestimmt sich nach den Bedingungen in Ziff. XIII.</p>

    <h3>XIII. Haftung des Mieters bei Schäden am Fahrzeug</h3>
    <p><u>1. Allgemeine Bedingungen</u></p>
    <p>Allgemein gilt, dass der Vermieter dazu berechtigt ist, die Schäden am Fahrzeug gegenüber dem Mieter geltend zu machen, welcher in Folge einer schuldhaften Verletzung der Mietvertragspflichten (der vorliegenden Bedingungen) entstanden sind.</p>
    <p>In welcher Höhe der Mieter zum Schadensersatz verpflichtet ist, hängt von dem Umfang des Fahrzeugschadens und des Verschuldens des Mieters ab.</p>
    <p>Der Vermieter legt dem Mieter zum Nachweis der Schadenshöhe einen Kostenvoranschlag oder ein Gutachten vor. Dem Mieter bleibt der Nachweis gestattet, dass der Schaden nicht oder nicht in der vom Vermieter geltend gemachten Höhe eingetreten ist.</p>
    <p>Sofern der Mieter bei Abschluss des Mietvertrages eine an der Vollkaskoversicherung orientierte Haftungsreduktion  vereinbart hat, die dafür vertraglich vereinbarte Gebühr bereits bei Fälligkeit der Rechnung vom Mieter gezahlt wurde und ihn kein Verstoß gegen die hier vereinbarten Bedingungen trifft, haftet der Mieter pro Schadenfall lediglich bis zur Höhe der vereinbarten Selbstbeteiligung zzgl einer Kostenpauschale für die Schadenbearbeitung.</p>
    <p>Dem Mieter bleibt der Nachweis gestattet, dass der geltend gemachten Schaden nicht oder nicht in der Höhe der geforderten Selbstbeteiligung  angefallen ist.</p>
    <p>Im Falle eines Haftpflichtschadens, bei dem der Schaden allein durch einen Dritten verursacht und verschuldet wurde, verpflichtet sich der Vermieter zur selbstständigen Regulierung des Fahrzeugschadens mit dem gegnerischen Haftpflichtversicherer. Im Fall eines unverschuldeten Unfalls haftet der Mieter nicht, es sei denn, er hat schuldhaft gegen seine Pflichten aus Ziff. XII verstoßen und hierdurch die Ermittlung des Unfallgegners und des Unfallhergangs vereitelt oder in einer Weise erschwert, dass dem Vermieter eine Schadloshaltung beim Unfallverursacher und dessen Haftpflichtversicherer nicht mehr möglich ist. Die Haftung des Mieters folgt in diesem Fall aus der schuldhaften Verletzung der in diesen Bedingungen vertraglich vereinbarten Pflichten zur Protokollierung eines Schadensereignisses.</p>

    <p><u>2. Festgestellte Schäden bei Anwesenheit des Mieters</u></p>
    <p>Werden bei Rückgabe des Fahrzeuges Schäden festgestellt, welche der Mieter oder der berechtigte Fahrer ganz oder teilweise zu vertreten haben, so werden diese Schäden im Rückgabeprotokoll festgehalten und vom Vermieter und Mieter unterzeichnet.</p>
    <p>Der Vermieter ist berechtigt, den Schaden durch die Einholung eines Kostenvoranschlages oder eines Gutachtens gegenüber dem Mieter geltend zu machen. Im Übrigen wird weiter wie in Ziff. XIII 1. vorgegangen.</p>
    <p>Unterschreibt der Mieter das Übergabeprotokoll nicht, wird weiter nach Ziff XIII 3. verfahren.</p>

    <p><u>3. Festgestellte Schäden bei Abwesenheit des Mieters</u></p>
    <p>Stellt der Vermieter bei der Besichtigung des Fahrzeuges in Abwesenheit des Mieters Schäden am Fahrzeug fest, so gelten folgende Vereinbarungen:</p>
    <p><u>a) Der Mieter hat ein Schadenformular ausgefüllt</u></p>
    <p>In diesem Fall bestimmt sich das Vorgehen nach den Regelungen in Ziff. XIII 1.</p>
    <p><u>b) Der Mieter hat schuldhaft kein Schadenformular ausgefüllt</u>
    <p>(a) Der Mieter hat das Fahrzeug als Verbraucher angemietet</p>
    <p>Hat der Mieter kein Schadenformular ausgefüllt und hat er das Fahrzeug als Verbrauchen angemietet, so ist der Vermieter verpflichtet, dem Mieter das Rückgabeprotokoll, eine Lichtbilddokumentation der Schäden sowie einen Kostenvoranschlag oder ein Gutachten über die erforderlichen Reparaturkosten zukommen zu lassen.</p>
    <p>Der Mieter kann binnen Frist von 14 Tagen nach Übersendung der Dokumente per Post oder per Mail Einwände gegen die festgestellten Schäden geltend machen. Die Frist beginnt zu laufen:</p>
    <ul>
      <li>wenn die Unterlagen per Email an den Mieter zugeschickt wurden: Ab Eingang der Email im Postfach des Mieters</li>
      <li>bei einer postialischen Zusendung: ab Eingang in den Briefkasten</li>
    </ul>
    <p>Die Einwände durch den Mieter können gegenüber dem Vermieter per Post oder per Mail geltend gemacht werden.</p>
    <p>Macht der Mieter innerhalb der 14-tägigen Frist keine Einwände gegen die Schäden oder die Höhe der Schäden geltend, behält sich der Vermieter das Recht vor, die zur Schadenbehebung erforderlichen Kosten (Reparaturkosten, Gutachterkosten, Abschleppkosten, Vorhaltekosten, Standgeldkosten, Gutachterhilfskosten, im Totalschadenfall den Wiederbeschaffungsaufwand, Rechtsverfolgungskosten) zuzüglich einer Kostenpauschale für die Bearbeitung des Schadensfalles in Rechnung zu stellen. Die Haftung des Mieters folgt in diesem Fall aus der schuldhaften Verletzung der vertraglich vereinbarten Pflichten zur Protokollierung eines Schadensereignisses.</p>
    <p>(b) Der Mieter hat das Fahrzeug als Unternehmer angemietet</p>
    <p>Hat der Mieter kein Schadenformular ausgefüllt und hat er das Fahrzeug als Unternehme angemietet, so ist der Vermieter verpflichtet, dem Mieter das Rückgabeprotokoll, eine Lichtbilddokumentation der Schäden sowie einen Kostenvoranschlag oder ein Gutachten über die erforderlichen Reparaturkosten zukommen zu lassen.</p>
    <p>Der Vermieter behält sich das Recht vor, die zur Schadensbehebung erforderlichen Kosten (Reparaturkosten, Gutachterkosten, Abschleppkosten, Vorhaltekosten, Standgeldkosten, Gutachterhilfskosten, im Totalschadenfall den Wiederbeschaffungsaufwand, Rechtsverfolgungskosten) zuzüglich einer Kostenpauschale für die Bearbeitung des Schadensfalles in Rechnung zu stellen. Die Haftung des Mieters folgt in diesem Fall aus der schuldhaften Verletzung der vertraglich vereinbarten Pflichten zur Protokollierung eines Schadensereignisses.</p>

    <h3>XIV. Instandhaltung des Fahrzeuges durch den Mieter während der Mietdauer</h3>

    <p>Der Mieter und der berechtigte Fahrer sind dazu verpflichtet, das angemietete Fahrzeug in dem Zustand zu erhalten, in dem es an den Mieter oder den berechtigten Fahrer durch den Vermieter übergeben wurde.</p>
    <p>Der Mieter und der berechtigte Fahrer sind weiterhin verpflichtet, bei einem Aufleuchten der Warnlampen im Fahrzeugdisplay während der Mietdauer ohne schuldhaftes Zögern die erforderlichen Maßnahmen zu ergreifen. Die Erforderlichkeit der einzelnen Maßnahmen wird dem Betriebshandbuch des angemieteten Fahrzeuges entnommen.</p>
    <p>Für das Nachfüllen des Kraftstoffes während der Mietdauer ist der Mieter oder der berechtigte Fahrer selbst verantwortlich. Eine Erstattung durch den Vermieter erfolgt nicht.</p>
    <p>Sofern während der Mietdauer solche Reparaturen notwendig werden, die der Erhaltung der  Betriebs – und Verkehrssicherheit des Fahrzeuges dienen, kann der Mieter diese Reparaturen bis zu einem Betrag in Höhe von € 100,00 selbst in Auftrag geben. Die Kosten hierfür werden gegen Vorlage einer Rechnung vom Vermieter erstattet. Reparaturen, die der Erhaltung der Betriebs – und Verkehrssicherheit dienen, jedoch den Betrag von € 100,00 übersteigen, dürfen nur mit Einwilligung des Vermieters in Auftrag gegeben werden.</p>
    <p>Sofern der Mieter die Notwendigkeit der Reparaturen nicht selbst zu vertreten hat, verpflichtet sich der Vermieter, diese Aufwendungen dem Mieter zu ersetzen.</p>
    <p>Dem Mieter ist es untersagt, mechanische Eingriffe am Fahrzeug vorzunehmen, sofern der Vermieter den konkret geplanten Eingriffen nicht ausdrücklich zugestimmt hat. Gleiches gilt für jegliche Veränderungen am Fahrzeug, die keine Reparatur darstellen, welche für die Erhaltung der Betriebs – und Verkehrssicherheit erforderlich sind.</p>

    <h3>XV. Fälligkeit des Mietzinses und Rechnungslegung</h3>

    <p>Der vertraglich vereinbarte Mietzins wird spätestens bei Abholung des Fahrzeuges fällig. Dem Mieter wird bei der Abholung des Fahrzeuges und Bezahlung des Mietzinses eine Rechnung ausgestellt.</p>

    <h3>XVI. Änderung der Buchung</h3>

    <p>Eine Änderung der vom Mieter getätigten Buchung kann bis zur Abholung des Fahrzeuges über Email, Telefon, schriftlich oder online getätigt werden.</p>

    <h3>XVII. Stornierung der Buchung; Verspätete Abholung</h3>

    <p>Eine geänderte Buchung (Änderung des Fahrzeuges, des Mietzeitraumes, des Zubehörs, etc) gilt nicht als Stornierung (endgültige Ablehnung der vereinbarten Leistung des Vermieters)</p>
    <p>Der Mieter und/oder der berechtigte Fahrer ist grundsätzlich verpflichtet, dass angemietete Fahrzeug zur vereinbarten Zeit am vereinbarten Ort abzuholen. Wird das angemietete Fahrzeug zur vereinbarten Zeit am vereinbarten Ort nicht abgeholt, geltend folgende Bedingungen:</p>

    <p><u>1. Stornierung der Buchung</u></p>
    <p>Das angemietete Fahrzeug kann vom Mieter kostenlos unter der Voraussetzung storniert werden, dass die Stornierung spätestens 48 h vor Mietbeginn bei dem Vermieter eingeht. Eine Stornierung ist nur schriftlich in Form einer Mitteilung per EMail oder Post möglich, es sei denn, der Vermieter stimmt einer anderen Stornierungsform ausdrücklich zu.</p>
    <p>Im Falle einer verspäteten Stornierung ( weniger als 48 Stunden vor Mietbeginn) ist der Vermieter berechtigt, dem Mieter eine Stornogebühr in Höhe von bis zu 50 % des vereinbarten Mietzinses in Rechnung zu stellen.</p>
    <p>Hat der Vermieter den Mietzins bereits vorab bezahlt, erhält er den Mietzins abzüglich der Stornogebühr zurückerstattet.</p>
    <p>Sofern keine Vorabzahlung durch den Mieter erfolgt ist, wird das Zahlungsmittel, welches dem Vermieter bei Vertragsbeginn angegeben wurde, in Höhe der Stornogebühr durch den Vermieter belastet.</p>
    <p>Im Falle dessen, dass keine Vorabzahlung erfolgt ist und Seitens des Mieters kein Zahlungsmittel angegeben wurde, wird der Vermieter eine Rechnung in Höhe der Stornogebühr an den Mieter stellen.</p>
    <p>Dem Mieter bleibt der Nachweis gestattet, dass dem Vermieter kein Schaden oder kein Schaden in der vom Vermieter beanspruchten Höhe der Stornogebühr angefallen ist.</p>
    <p>2. Keine Stornierung</p>
    <p>Wird die Buchung Seitens des Mieters überhaupt nicht storniert und holt der Mieter oder der berechtigte Fahrer das Fahrzeug nicht zur vereinbarten Zeit am vereinbarten Ort ab (No Show fee), so behält sich der Mieter das Recht vor, eine Stornogebühr in Höhe von 50 % des vereinbarten Mietzinses in Rechnung zu stellen.</p>
    <p>Dem Mieter bleibt der Nachweis gestattet, dass dem Vermieter kein Schaden oder kein Schaden in der vom Vermieter beanspruchten Höhe der No-Show-Stornogebühr angefallen ist.</p>
    <p>Hat der Vermieter den Mietzins bereits vorab bezahlt, erhält er den Mietzins abzüglich der No-Show-Stornogebühr zurückerstattet.</p>
    <p>Sofern keine Vorabzahlung durch den Mieter erfolgt ist, wird das Zahlungsmittel, welches dem Vermieter bei Vertragsbeginn angegeben wurde, in Höhe der No-Show-Stornogebühr durch den Vermieter belastet.</p>
    <p>Im Falle dessen, dass keine Vorabzahlung erfolgt ist und Seitens des Mieters kein Zahlungsmittel angegeben wurde, wird der Vermieter eine Rechnung in Höhe der No-Show-Stornogebühr an den Mieter stellen.</p>
    <h3>XVIII. Wartepflicht des Vermieters bei Verspätung der Abholung</h3>
    <p>Sofern der Mieter das Fahrzeug zur vereinbarten Zeit am vereinbarten Ort nicht abholt und keine Stornierung vorliegt, ist der Vermieter verpflichtet,  59 Minuten nach der vereinbarten Zeit das Fahrzeug für den Mieter vorzuhalten. Wird nach dieser Zeit das Fahrzeug immer noch nicht am vereinbarten Ort abgeholt, ist der Vermieter zur Weitervermietung berechtigt.</p>
    <p>Hinsichtlich der Stornogebühren geltend dann die Bedingungen in Ziff. XVI Nr. 2.</p>

    <h3>XIX. Ortungssysteme</h3>

    <p>Die Fahrzeuge des Vermieters sind mit einer Technik ausgestattet, welche es dem Vermieter ermöglicht, die genaue Position des Fahrzeuges zu ermitteln. Der Mieter erklärt sich mit der Erhebung, Speicherung und Nutzung dieser Daten bereit.</p>

    <h3>XX. Haftung des Vermieters</h3>

    <p>Die Haftung des Vermieters wegen Verletzung vertraglicher Pflichten wird auf Vorsatz und grobe Fahrlässigkeit beschränkt. Gleiches gilt für dessen gesetzliche Vertreter und Erfüllungsgehilfen. Der Schadensersatzanspruch aufgrund der Verletzung wesentlicher Vertragsbestandteile wird auf den vertragstypischen und vorhersehbaren Schaden begrenzt.</p>
    <p>Die Haftung wegen schuldhafter Verletzung des Lebens, des Körpers oder der Gesundheit bleibt davon unberührt, dies gilt auch für die zwingende Haftung nach dem Produkthaftungsgesetz. Eine Haftung der Vertreter und Erfüllungsgehilfen wegen schuldhafter Verletzung des Lebens, des Körpers oder der Gesundheit bleibt ebenfalls vom oben genannten Haftungsausschluss unberührt.</p>
    <p>Der Vermieter ist ebenfalls nicht  zur Verwahrung von Gegenständen des Mieters verpflichtet, welche der Mieter nach Rückgabe des  Fahrzeuges im angemieteten Fahrzeug hinterlässt. Er haftet insoweit lediglich für Vorsatz und grobe Fahrlässigkeit.</p>

    <h3>XXI. Kündigung des Mietvertrages</h3>
    <p>Sowohl Vermieter als auch der Mieter sind berechtigt, das Vertragsverhältnis aus wichtigen Grund zu kündigen. Ein wichtiger Grund liegt für den Vermieter insbesondere in folgenden Fällen vor:</p>

    <ul>
      <li>eine erhebliche Verschlechterung der Vermögensverhältnisse des Mieters</li>
      <li>eine verbotene Nutzung des Fahrzeuges</li>
      <li>ein grob unsachgemäßer Gebrauch des Fahrzeuges</li>
      <li>wenn der begründete Verdacht einer Straftat vorliegt</li>
      <li>wenn der Mieter oder der berechtigte Fahrer bewusst unwahre Angaben zu Personendaten gemacht haben oder die Angaben derart unvollständig getätigt wurden, dass eine Identifikation der Vertragsparteien und deren ladungsfähiger Anschrift nicht möglich sind</li>
      <li>ein vom Mieter oder berechtigten Fahrer schuldhaft verursachter erheblicher Schaden am angemieteten Fahrzeug</li>
      <li>der Mieter mit der vor Mietbeginn zu entrichtende Kaution nach Fälligkeit mindestens 3 Tage im Verzug ist</li>
      <li>der Mieter dem Vermieter das Recht zur Besichtigung des Fahrzeuges nicht einräumt, obwohl auf Seiten des Vermieters hierzu ein berechtigtes Interesse besteht und die Ermöglichung der Besichtigung dem Mieter zumutbar ist.</li>
    </ul>

    <p>Kündigt der Vermieter das Mietverhältnis außerordentlich, so ist der Mieter zur unverzüglichen Rückgabe des Fahrzeuges, der Fahrzeugpapiere, der Fahrzeugschlüssen und des angemieteten Zubehörs verpflichtet.</p>

    <h3>XXII. Anwendbares Recht</h3>
    <p>Bei Streitigkeiten in Bezug auf den Mietvertrag und die sich hieraus ergebenden Leistungen und Pflichten findet deutsches Recht Anwendung.</p>

    <h3>XXIII. Gerichtsstandsvereinbarung</h3>
    <p>Ist der Mieter Kaufmann, juristische Person des öffentlichen Rechts oder öffentliches Sondervermögen, so ist der allgemeine Gerichtsstand für alle Streitigkeiten 73614 Schorndorf. Sofern der Mieter keinen allgemeinen Gerichtsstand in Deutschland hat und Kaufmann, juristische Person des öffentlichen Rechts oder öffentliches Sondervermögen ist, ist Gerichtsstand für alle Streitigkeiten ebenfalls 73614 Schorndorf</p>


  </div>
</template>

<script>
export default {
  name: 'legal',
  data () {
    return {}
  },
  methods: {},
  mounted () {
  }
}
</script>
